import React from "react"
import { graphql, Link } from "gatsby"
import ReactTooltip from 'react-tooltip';

import Layout from "../../components/promotions/layout"
import SEO from "../../components/common/seo"

import PaymentLink from "../../components/promotions/paymentLink"

import { unslugify } from "../../utils/text"
import { communityPromotionLink } from "../../components/promotions/constants"


const TopicsTrafficPage = ({data, path}) => {
  const allTopics = data.allTopics.nodes;
  const promotions = data.promotions.nodes;
  const maxPromotionsPerTopic = 4;

  // get data for table
  const maxVisitors = 1500; // for coloring
  const maxPageviews = 3000; // for coloring
  const pageviewsAdOpportunity = 1000;
  const promotionDays = 30;
  const clickCutoff = 99;
  const CpcCutoff = 1;
  const average = (arr) => {
    if (!arr || !arr.length) return null;
    return arr.reduce(( p, c ) => p + c, 0 ) / arr.length
  }; // util
  var rankedTopics = allTopics.map(k => {
    const topicPromotions = promotions.filter(p => p.topic === k.slug);
    const finishedPromotions = topicPromotions.filter(p => p.active === "FALSE");
    const promoClicks = parseInt(average(finishedPromotions.filter(p => p.clicks_day).map(p => p.clicks_day)) * promotionDays);
    return Object.assign(k, {
      'visitorOpacity': parseInt(Math.min(k.visitors, maxVisitors) * 100 / maxVisitors),
      'pageviewOpacity': parseInt(Math.min(k.views, maxPageviews) * 100 / maxPageviews),
      'count': finishedPromotions.length,
      'promotionsActive': topicPromotions.filter(p => p.active === "TRUE").length,
      // 'promotionViews': average(topicPromotions.filter(p => p.views).map(p => parseInt(p.views))),
      'promotionClicks': promoClicks,
      'promotionClicksDay': average(finishedPromotions.filter(p => p.clicks_day).map(p => p.clicks_day)),
      'promotionCPC': promoClicks ? 99 / promoClicks : null,
    })
  }).sort((a,b) => (a.views < b.views) ? 1 : ((b.views < a.views) ? -1 : 0)).filter(t => t.views >= 200);

  return (
    <Layout pageTitle="Traffic Reports" enableShare={true}>
      <SEO title="Traffic | Topics" description="Traffic data for Hive Index topics"
        path={'/partners/traffic/'} generateImage={true}
      />

      <div className="relative">
        {/*<div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>*/}
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Topics by highest traffic</h2>
        </div>

        <div className="text-gray-500 mt-1">
          Each of the <Link to="/topics/" className="text-rose-600 font-semibold">topic pages</Link> on the Hive Index have up to 3 advertising spots available each month.
          You may <Link to="/partners/community/" className="text-rose-600 font-semibold">promote your community</Link> at the top of your topic page and on the homepage with a <span className="border-b-2 border-yellow-400 border-dotted" data-for="table-tooltip" data-tip="If your communtity gets <99 referrals during your promotion month, we will continue running it until you get 99+">$1 CPC guarantee</span> for a month-long promotion.
          Below are the monthly traffic stats for each topic page.
        </div>
      </div>

      <div className="mt-2 text-gray-500">
        <ReactTooltip id={`table-tooltip`} place="top" effect="solid" backgroundColor="black" textColor="white"
          className="max-w-xl"
        />
        <div className="overflow-x-auto">
           <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  data-for="table-tooltip" data-tip="Topic on which you can run a community promotion"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Topic</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b border-gray-500 border-dotted cursor-pointer"
                  data-for="table-tooltip" data-tip="Pageviews on this topic each month"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Pageviews (/mo)</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b border-gray-500 border-dotted cursor-pointer"
                  data-for="table-tooltip" data-tip="Unique visitors on this topic each month"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Visitors (/mo)</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b border-gray-500 border-dotted cursor-pointer"
                  data-for="table-tooltip" data-tip="Current active promotions on topic page"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Active Promos</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b border-gray-500 border-dotted cursor-pointer"
                  data-for="table-tooltip" data-tip="Estimate for clicks per day to a promoted community"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Referrals /mo</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-b border-gray-500 border-dotted cursor-pointer"
                  data-for="table-tooltip" data-tip="Estimated advertising cost per click"
                >
                  <span className="border-b-2 border-yellow-400 border-dotted">Promo CPC</span>
                </th>
                
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 hidden sm:block">
                  <span className="sr-only">Promote</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {rankedTopics.map((topic, idx) => (
                <tr key={topic.slug} className={idx % 2 === 0 ? undefined : 'bg-gray-50'}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    <Link to={`/topics/${topic.slug}/`} className="text-rose-600">{topic.name}</Link>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                    <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-6 mr-4">
                      <div className="absolute bg-yellow-300 rounded-sm p-1 h-full" style={{width: topic.pageviewOpacity + '%', opacity: topic.pageviewOpacity + '%'}}></div>
                      <div className="absolute p-0.5 px-2 max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                        {topic.views}
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                    <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-6 mr-4">
                      <div className="absolute bg-yellow-300 rounded-sm p-1 h-full" style={{width: topic.visitorOpacity + '%', opacity: topic.visitorOpacity + '%'}}></div>
                      <div className="absolute p-0.5 px-2 max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                        {topic.visitors}
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold flex items-center">
                    {topic.promotionsActive}<span className="opacity-50 font-semibold ml-1"> / {Math.max(topic.promotionsActive, maxPromotionsPerTopic)}</span>

                    {topic.promotionsActive < maxPromotionsPerTopic && topic.views >= pageviewsAdOpportunity ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2 cursor-pointer text-yellow-500"
                        data-for="table-tooltip" data-tip="Great advertising opportunity based on current page views"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                      </svg>
                    ) : ''}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {topic.promotionClicks > 99 ? (
                      <span className="font-bold border-b border-dotted cursor-pointer" data-for="table-tooltip" data-tip={`Estimate based on ${topic.count} previous promotion${topic.count === 1 ? '' : 's'}`}>
                        {topic.promotionClicks}
                      </span>
                    ) : (
                      <span className="text-gray-400 border-b border-dotted cursor-pointer" data-for="table-tooltip" data-tip={`No estimate available yet, referrals is based on $1 CPC`}>
                        99+
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {topic.promotionCPC ? (
                      <span className="font-bold border-b border-dotted cursor-pointer" data-for="table-tooltip" data-tip={`Based on ${topic.count} previous promotion${topic.count === 1 ? '' : 's'}`}>
                        {topic.promotionCPC < CpcCutoff ? `$${topic.promotionCPC.toFixed(2)}` : `$${CpcCutoff}`}
                      </span>
                    ) : (
                      <span className="text-gray-400 border-b border-dotted cursor-pointer"
                        data-for="table-tooltip" data-tip="No estimate available yet, data shown based on $1 CPC guarantee."
                      >{`<`}${CpcCutoff}</span>
                    )}
                  </td>
                  <td className="relative text-right text-sm font-medium hidden sm:block">
                    <PaymentLink
                      url={communityPromotionLink}
                      params={{'utm_content': 'community_promotion'}}
                      title="Advertise"
                      className="text-sm px-3 py-2 font-bold bg-rose-500 cursor-pointer hover:bg-rose-600 text-white rounded-md"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-2 text-sm">For all other topic pages (or any questions about promotions), <a className="text-rose-500 hover:underline font-bold" href="mailto:contact@thehiveindex.com">send an email</a>.</div>
          
      </div>


      {/*<div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Advertise your community</h2>
        </div>
      </div>

      <div className="mt-2 text-gray-500">
        <Link to="/partners/community/" className="font-semibold underline text-rose-500">Community promotion</Link>
      </div>*/}

    </Layout>
  )
}

export const query = graphql`
query {
  allTopics: allGoogleSpreadsheetSourceTopics(sort: {fields: visitors, order: DESC}) {
    totalCount
    nodes {
      slug
      isRoot
      name
      id
      count
      image
      people
      category
      visitors
      views
    }
  }
  promotions: allGoogleSpreadsheetSourcePromoted(filter: {sponsored: {eq: "1"}}) {
    nodes {
      id
      active
      days
      paid
      views
      visitors
      sponsored
      topic
      clicks
      clicks_day
      clicks_dollar
      cost_click
    }
  }
}
`

export default TopicsTrafficPage
